<template>
	<div>
		<app-layout>
			<template v-slot:header>
				<Header :title="$t('semester_settings')"
				        :isFilter="false"/>
			</template>
			<template v-slot:header-mobile>
				<HeaderMobile :title="$t('semester_settings')"
				              :isFilter="false"/>
			</template>
			<div class="row">
				<div class="col-12 col-md-12 col-xl-12 col-xxl-12 mt-4" v-if="form">
					<ValidationObserver ref="form">
						<div class="row">
							<div class="col-6 col-md-6 col-xl-4">
								<ValidationProvider name="active_semester_id" rules="required" v-slot="{valid, errors}">
									<b-form-group :label="$t('active_semester')">
										<semesters-selectbox
											:validate-error="errors[0]"
											v-model="form.active_semester_id">
										</semesters-selectbox>
									</b-form-group>
								</ValidationProvider>
							</div>
                            <div class="col-6 col-md-6 col-xl-4">
                                <ValidationProvider name="registration.semester_id" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('registration_semester')">
                                        <semesters-selectbox
                                            :validate-error="errors[0]"
                                            v-model="form['registration.semester_id']">
                                        </semesters-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
							<div class="col-6 col-md-6 col-xl-4">
								<ValidationProvider name="course_registration.semester_id" rules="required" v-slot="{valid, errors}">
									<b-form-group :label="$t('course_registration_semester')">
										<semesters-selectbox
											:validate-error="errors[0]"
											v-model="form['course_registration.semester_id']">
										</semesters-selectbox>
									</b-form-group>
								</ValidationProvider>
							</div>


							<div class="col-12 col-md-12 col-xl-12 col-xxl-12">
								<div class="row">
									<div class="col-sm-6 col-xs-12 mb-5">
										<b-button @click="save"
										          type="button"
										          variant="primary"
										          class="btn-lg d-flex justify-content-between align-items-center">
											{{ $t('save').toUpper() }}
										</b-button>
									</div>
								</div>
							</div>

						</div>
					</ValidationObserver>
				</div>
			</div>
		</app-layout>
	</div>
</template>
<script>
	// Template
	import AppLayout from "@/layouts/AppLayout";
	import Header from "@/layouts/AppLayout/Header";
	import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

	// Components
	import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
	import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";

	import {ValidationObserver, ValidationProvider} from "vee-validate";
    // Services
    import SettingsService from "@/services/SettingsService";

	export default {
		components: {
			AppLayout,
			Header,
			HeaderMobile,

			AcademicYearsSelectbox,
			SemestersSelectbox,
			ValidationProvider,
			ValidationObserver,
		},
		data() {
			return {
				form: null,
			}
		},
		metaInfo() {
			return {
				title: this.$t("semester_settings")
			}
		},
		methods: {
			async save() {
                if(this.checkPermission('settings_semester_store')){
                    const isValid = await this.$refs.form.validate();
                    if (isValid) {
                         let formData = {
                             active_semester_id: this.form.active_semester_id,
                             ["course_registration.semester_id"]: this.form["course_registration.semester_id"],
                             ["prep.academic_year"]: this.form["prep.academic_year"],
                             ["registration.semester_id"]: this.form["registration.semester_id"]
                         }

                        SettingsService.storeSemesterSettings(formData).then(response => {
                            this.$toast.success(this.$t('api.' + response.data.message))
                        }).catch(e => {
                            this.showErrors(e)
                        })
                    }
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }

			},
			getSettings() {
				SettingsService.getSemesterSettings()
                               .then(response => {
                                   this.form = response.data.data
                               })
			}
		},
		created() {
			this.getSettings()
		}
	}
</script>
